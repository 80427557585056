#inner-page {
  .infographic {
    margin: auto;
    display: block;
  }

  h1 {
    text-align: center;
  }

  .innerpageimg {
    border-radius: 10px;
    float: right;
    padding: 5px;
    margin-left: 1rem;
    max-width: 295px;
  
    &.left {
      float: left;
    }
  
    &.centre {
      display: block;
      float: none;
      margin: auto;
      max-width: 250px;
    }
  
    &.large {
      max-width: 400px;
    }

    @media (max-width: 991px) {
      float: none;
      margin: auto;
      width: 100%;
  
      &.left {
        float: none;
      }
  
      &.centre {
        max-width: 100%;
      }
    }
  }

  li {
    font-size: 0.9rem;
  }

  .infographicbookingtext {
    color: $primary-color;
  }

  .interimservice {
    background: #EFB38B;
  }

  .fullservice {
    background: #BFB7A4;
  }

  .majorservice {
    background: #D3B672;
  }

  .interimservice,
  .fullservice,
  .majorservice {
    padding: 20px 10px;
    padding-top: 20px;
    border-radius: 20px;

    p,
    h3
    {
      text-align: center;
    }

    .bookingbtn {
      padding: 20px;
      display: table;
      margin: auto;
      color: #ffffff;
      background: #000000;
      border-radius: 5px;

      p {
        margin-bottom: 0;
      }
    }

    li {
      font-size: 0.8rem;
    }
  }

  table {
    margin-bottom: 1rem;
    
    th {
      padding: 5px 10px;
    }

    td {
      font-size: 0.9rem;
    }
  }

  .blogimage {
    max-width: 50%;
  }
}

#mobile-booking-tool {
  background: $primary-color;
  padding: 15px;
  border-radius: 10px;

  h2,
  p {
    color: #fff;
    text-align: center;
  }

  iframe {
    min-height: 150px;
  }

  @media (min-width: 767px) {
    display: none;
  }
}